import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout } from './routes/Layout.tsx';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import UserContextProvider from './features/auth/context/userContext.tsx';
import { AppsPage } from './features/apps/pages/Apps.tsx';
import { LoginPage } from './features/auth/pages/Login.tsx';
import { RecordsSinglePage } from './features/records/pages/RecordsSinglePage.tsx';
import { ReportsList } from './features/reports/pages/ReportsList.tsx';
import { AppRoutes, DashboardsRoutes, ObjectsRoutes, RecordsRoutes, ReportsRoutes } from './routes';
import { ReportPage } from './features/reports/pages/Report.tsx';
import { toast } from 'react-hot-toast';
import { Toaster } from './components/Toaster.tsx';
import { ToastContent } from './components/ToastContent.tsx';

import AlertIcon from './assets/icons/alert-circle.svg?react';
import { SegmentsPage } from './features/segments/pages/Segments.tsx';
import { RecordsListPageWrapper } from './features/records/pages/RecordsListPageWrapper.tsx';
import { RecordsIndex } from './features/records/pages/RecordsIndex.tsx';
import { EventsPage } from './features/events/pages/Events.tsx';
import { Dashboard } from './features/dashboard/pages/Dashboard.tsx';
import React, { lazy, Suspense } from 'react';
import { MetricList } from './features/metrics/pages/MetricList.tsx';
import { PageViewTracker } from './tracking';
import { SignUpPage } from './features/sign-up/pages/SignUpPage.tsx';
import { MemberOnboardingPage } from './features/members/onboarding/pages/MemberOnboardingPage.tsx';
import { AccountOnboardingPage } from './features/account/onboarding/pages/AccountOnboardingPage.tsx';
import { WorkspaceContextRoute } from './features/auth/components/WorkspaceContextRoute.tsx';
import { ProtectedRoute } from './features/auth/components/ProtectedRoute.tsx';
import { AccountContextRoute } from './features/auth/components/AccountContextRoute.tsx';
import { EditMetricPage } from './features/metrics/pages/EditMetricPage.tsx';
import { CreateMetricPage } from './features/metrics/pages/CreateMetricPage.tsx';
import { DashboardsList } from './features/dashboard/pages/DashboardsList.tsx';
import { CreateDashboardPage } from './features/dashboard/components/CreateDashboardPage.tsx';
import { EditDashboardPage } from './features/dashboard/components/EditDashboardPage.tsx';
import { AccessKeysPage } from './features/accesskeys/AccessKeysPage.tsx';
import { PlanningEmptyPage } from './features/planning/pages/PlanningEmptyPage.tsx';
import { IntegrationsPage } from './features/integrations/pages/IntegrationsPage.tsx';
import { AxiosError } from 'axios';
import { ErrorResponse } from '@bigdelta/lib-shared';
import { MembersList } from './features/members/MembersList.tsx';
import { AcceptInvitationPage } from './features/invitations/AcceptInvitationPage.tsx';
import { ForgotPasswordPage } from './features/auth/pages/ForgotPassword.tsx';
import { ResetPasswordPage } from './features/auth/pages/ResetPassword.tsx';
import { IntegrationsSlackAuthPage } from './features/integrations/pages/IntegrationsSlackAuthPage.tsx';
import { AutomationsListPage } from './features/automations/pages/AutomationsListPage.tsx';
import { AutomationsCreatePage } from './features/automations/pages/AutomationsCreatePage.tsx';
import { AutomationsViewPage } from './features/automations/pages/AutomationsViewPage.tsx';
import { WorkspaceGeneralSettingsPage } from './features/settings/workspace/pages/WorkspaceGeneralSettingsPage.tsx';
import { ProfileSettingsPage } from './features/settings/profile/pages/ProfileSettingsPage.tsx';
import { ObjectsListPage } from './features/objects/pages/ObjectsListPage.tsx';
import { ObjectPage } from './features/objects/pages/ObjectPage.tsx';
import { SettingsLayout } from './routes/SettingsLayout.tsx';
import { BillingPage } from './features/settings/billing/BillingPage.tsx';
import { IntegrationProfilePage } from './features/integrations/pages/IntegrationProfilePage.tsx';
import { DataListPage } from './features/data/pages/DataListPage.tsx';
import { WorkspaceOnboarding } from './features/workspaces/onboarding/pages/WorkspaceOnboarding';

const Tree = lazy(() => import('./features/tree/pages/Tree.tsx'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const axiosError = error as AxiosError<ErrorResponse>;
        return axiosError.response?.status !== 401 && failureCount < 4;
      },
      staleTime: 1000,
      refetchOnWindowFocus: false,
    },
  },
  mutationCache: new MutationCache({
    onError: (error) => {
      if (error instanceof AxiosError && error.response?.status === 401) {
        window.location.href = AppRoutes.LOGIN;
      }
    },
  }),
  queryCache: new QueryCache({
    onError: (error: unknown, query) => {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          window.location.href = AppRoutes.LOGIN;
          return;
        }

        if (query.meta?.suppressError && Array.isArray(query.meta?.suppressError) && query.meta?.suppressError.includes(error.response?.status)) {
          return;
        }

        toast(<ToastContent message={error?.response?.data?.message} />, {
          icon: <AlertIcon className="h-5 w-5 text-m-red-600" />,
        });
      }
    },
  }),
});

const App = () => {
  return (
    <BrowserRouter>
      <UserContextProvider>
        <QueryClientProvider client={queryClient}>
          <PageViewTracker />
          <Routes>
            <Route path={AppRoutes.ACCEPT_INVITATION} Component={AcceptInvitationPage} />
            <Route path={AppRoutes.LOGIN} Component={LoginPage} />
            <Route path={AppRoutes.FORGOT_PASSWORD} Component={ForgotPasswordPage} />
            <Route path={AppRoutes.RESET_PASSWORD} Component={ResetPasswordPage} />

            <Route path={AppRoutes.SIGN_UP} Component={SignUpPage} />
            <Route Component={ProtectedRoute}>
              <Route path={AppRoutes.MEMBERS_ONBOARDING} Component={MemberOnboardingPage} />
              <Route path={AppRoutes.ACCOUNT_ONBOARDING} Component={AccountOnboardingPage} />

              <Route Component={AccountContextRoute}>
                <Route path={AppRoutes.WORKSPACES_ONBOARDING} Component={WorkspaceOnboarding} />
                <Route Component={WorkspaceContextRoute}>
                  <Route Component={SettingsLayout}>
                    <Route path={AppRoutes.SETTINGS_WORKSPACE_GENERAL} Component={WorkspaceGeneralSettingsPage} />
                    <Route path={AppRoutes.SETTINGS_PROFILE} Component={ProfileSettingsPage} />
                    <Route path={AppRoutes.ACCESS_KEYS} Component={AccessKeysPage} />
                    <Route path={AppRoutes.VIEW_INTEGRATION} Component={IntegrationProfilePage} />
                    <Route path={AppRoutes.INTEGRATIONS} Component={IntegrationsPage} />
                    <Route path={AppRoutes.INTEGRATIONS_SLACK_AUTH} Component={IntegrationsSlackAuthPage} />
                    <Route path={AppRoutes.INTEGRATIONS_STRIPE_AUTH} Component={IntegrationsPage} />
                    <Route path={AppRoutes.MEMBERS} Component={MembersList} />
                    <Route path={ObjectsRoutes.SETTINGS_WORKSPACE_LIST} Component={ObjectsListPage} />
                    <Route path={ObjectsRoutes.SETTINGS_WORKSPACE_PREVIEW} Component={ObjectPage} />
                    <Route path={ObjectsRoutes.SETTINGS_WORKSPACE_NEW} Component={ObjectPage} />
                    <Route path={AppRoutes.BILLING} Component={BillingPage} />
                  </Route>

                  <Route Component={Layout}>
                    <Route path={AppRoutes.REPORTS}>
                      <Route index Component={ReportsList} />
                      <Route path={ReportsRoutes.CREATE_REPORT} Component={ReportPage} />
                      <Route path={ReportsRoutes.VIEW_REPORT} Component={ReportPage} />
                    </Route>
                    <Route path={AppRoutes.SEGMENTS}>
                      <Route index path="*" Component={SegmentsPage} />
                    </Route>
                    <Route path={AppRoutes.METRICS} Component={MetricList}>
                      <Route path="new" Component={CreateMetricPage} />
                      <Route path=":id" Component={EditMetricPage} />
                    </Route>

                    <Route path={AppRoutes.PLANNING} Component={PlanningEmptyPage} />

                    <Route path={AppRoutes.EVENTS} Component={EventsPage} />
                    <Route path={AppRoutes.APPS} Component={AppsPage} />
                    <Route path={RecordsRoutes.VIEW_RECORD} Component={RecordsSinglePage} />
                    <Route path={RecordsRoutes.LIST} Component={RecordsListPageWrapper} />
                    <Route path={RecordsRoutes.INDEX} Component={RecordsIndex} />

                    <Route path={AppRoutes.AUTOMATIONS} Component={AutomationsListPage} />
                    <Route path={AppRoutes.AUTOMATIONS_CREATE} Component={AutomationsCreatePage} />
                    <Route path={AppRoutes.AUTOMATIONS_VIEW(':id')} Component={AutomationsViewPage} />
                    <Route path={AppRoutes.DATA} Component={DataListPage} />

                    <Route
                      path={AppRoutes.TREE}
                      element={
                        <Suspense>
                          <Tree />
                        </Suspense>
                      }
                    />
                    <Route path={DashboardsRoutes.INDEX} Component={DashboardsList}>
                      <Route path={DashboardsRoutes.CREATE_DASHBOARD} Component={CreateDashboardPage} />
                      <Route path={DashboardsRoutes.EDIT_DASHBOARD} Component={EditDashboardPage} />
                    </Route>
                    <Route path={DashboardsRoutes.VIEW_DASHBOARD} element={<Dashboard />} />
                    <Route path={AppRoutes.ROOT} Component={DashboardsList} />
                  </Route>
                </Route>
              </Route>
            </Route>
            {/* <Route path="*" element={<Navigate to={AppRoutes.REPORTS} replace />} /> */}
          </Routes>
          <Toaster />
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </UserContextProvider>
    </BrowserRouter>
  );
};

export default App;
