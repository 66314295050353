import { useMemo } from 'react';
import { MembersConfigTableLayoutListData, MetadataResourcesPropertiesNamesListData, QueryEventsCreateData } from '@bigdelta/lib-api-client';
import { ColumnDef, ColumnHelper } from '@tanstack/react-table';
import { suppressConsoleWarn } from '../../../shared/utils/suppressConsoleWarn';
import { format } from 'date-fns';
import { CellContentDefault } from '../../../shared/tables/components/CellContentDefault';

import EventIcon from '../../../assets/icons/cursor-click-01.svg?react';
import { ColumnActionType, DataTableColumnActions } from '../../../shared/tables/components/DataTableColumnActions';
import { RelationshipEntityType, TableColumnType, TableResourceType, TableSortDirection } from '@bigdelta/lib-shared';
import { useTableLayout } from '../../../shared/tables/hooks/useTableLayout';

import PlusIcon from '../../../assets/icons/plus.svg?react';
import { resolvePropertyValue } from '../../records/hooks/useRecordsTableColumns/utils/resolvePropertyValue';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';
import { useQuery } from '@tanstack/react-query';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { renderValue } from '../../../shared/tables/utils/renderValue';
import { getTableSortEnumVal } from '../../../shared/tables/utils/getTableSortEnumVal';
import { getRelatedObjectsList } from '../../../shared/tables/utils/getRelatedObjectsList';
import { renderRelatedRecordColumnHeader } from '../../../shared/tables/utils/renderRelatedRecordColumnHeader';
import { MET_REMOTE_ID } from '../../records/const';
import { resolveRelations } from '../../records/hooks/useRecordsTableColumns/utils/resolveRelations';
import { CellContentRelations } from '../../../shared/tables/components/CellContentRelations';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';
import { CellContentTags } from '../../../shared/tables/components/CellContentTags';
import { getUserFriendlyPropertyName } from '../../records/hooks/useRecordsTableColumns/utils/getUserFriendlyPropertyName';
import { useTableLayoutData } from '../../../shared/tables/hooks/useTableLayoutData';

const getFieldActions = (column: MembersConfigTableLayoutListData['columns'][number]): ColumnActionType[] => {
  const actions = [ColumnActionType.SORT];

  if (column.field?.field_name === 'created_at') {
    actions.push(ColumnActionType.ADD_RIGHT);
  }

  return actions;
};

interface UseTableColumnsArgs {
  columnHelper: ColumnHelper<QueryEventsCreateData['items'][number]>;
  metadataAttributeNameItems?: MetadataResourcesPropertiesNamesListData['items'];
}

export const useEventsTableColumns = ({ metadataAttributeNameItems, columnHelper }: UseTableColumnsArgs) => {
  const { tableLayoutQuery, tableLayoutUpdateMutation } = useTableLayoutData({ resourceType: TableResourceType.EVENT });
  const { onActionSelect, extractRelationshipInfo } = useTableLayout({
    resourceType: TableResourceType.EVENT,
    layout: tableLayoutQuery.data,
    onChange: tableLayoutUpdateMutation.mutate,
  });

  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();
  const relationshipsQuery = useQuery({
    queryKey: queryKeys.relationships(),
    queryFn: () => bigdeltaAPIClient.v1.relationshipsList({ workspace_id: currentWorkspaceId }),
  });
  const objectsQuery = useQuery({
    queryKey: queryKeys.list('object'),
    queryFn: () => bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId, status: 'ACTIVE_AND_VIRTUAL' }),
  });

  return useMemo(() => {
    if (!metadataAttributeNameItems || !relationshipsQuery.data || !objectsQuery.data) {
      return [];
    }

    const eventPropertyColumns =
      tableLayoutQuery.data?.columns
        .map((column, index) => {
          const columnField = column.field;

          if (column.type === TableColumnType.FIELD && columnField) {
            return columnHelper.accessor((event) => event[columnField.field_name], {
              id: columnField.field_name,
              header: () => (
                <DataTableColumnActions
                  key={columnField.field_name}
                  resourceType={TableResourceType.EVENT}
                  actions={getFieldActions(column)}
                  onSelect={(action) => onActionSelect(columnField.field_name, action, undefined, column)}
                  sort={column.sort as TableSortDirection}
                >
                  {getUserFriendlyPropertyName(columnField.field_name)}
                </DataTableColumnActions>
              ),
              size: 250,
              cell: (props) => {
                let value;
                suppressConsoleWarn(() => {
                  if (columnField.field_name === 'created_at') {
                    value = format(new Date(props.getValue()), 'yyyy-MM-dd HH:mm:ss');
                  } else {
                    value = props.getValue();
                  }

                  return value;
                });
                return <CellContentDefault text={value} leadingIcon={columnField.field_name === 'event_name' ? EventIcon : undefined} />;
              },
            });
          }

          if (column.type === TableColumnType.PROPERTY && column.property) {
            const columnProperty = column.property;

            if (!columnProperty) {
              return;
            }

            return columnHelper.accessor(`properties.${column.property.property_name}`, {
              id: column.property?.property_name,
              header: () => (
                <DataTableColumnActions
                  resourceType={TableResourceType.EVENT}
                  actions={[
                    ColumnActionType.ADD_LEFT,
                    ColumnActionType.ADD_RIGHT,
                    ...(index !== 0 ? [ColumnActionType.MOVE_LEFT] : []),
                    ...(index !== tableLayoutQuery.data.columns.length - 1 ? [ColumnActionType.MOVE_RIGHT] : []),
                    ColumnActionType.SORT,
                    ColumnActionType.HIDE,
                  ]}
                  onSelect={(action) => onActionSelect(columnProperty.property_name, action, undefined, column)}
                  sort={getTableSortEnumVal(column.sort)}
                >
                  {getUserFriendlyPropertyName(column.property?.property_name)}
                </DataTableColumnActions>
              ),
              size: 200,
              cell: (info) => {
                let value;
                suppressConsoleWarn(() => {
                  value = info.getValue();
                });

                if (Array.isArray(value)) {
                  return <CellContentTags tags={value} />;
                }

                const fullText = Array.isArray(value) ? value.join(', ') : undefined;

                return <CellContentDefault text={renderValue(value)} fullText={fullText} />;
              },
            });
          }

          if (column.type === TableColumnType.RELATIONSHIP) {
            const { names: relationshipNames, property } = extractRelationshipInfo(column.relationship);

            if (!property) {
              return;
            }

            const relatedObjectsList = getRelatedObjectsList(
              relationshipNames,
              RelationshipEntityType.EVENT,
              undefined,
              relationshipsQuery.data?.relationships,
              objectsQuery.data?.objects
            ).flatMap((o) => (o ? [o] : []));

            if (!relatedObjectsList || relatedObjectsList?.find((object) => !object)) {
              // throw error
              return;
            }

            const isPropertyId = property.property_name === MET_REMOTE_ID;

            return columnHelper.accessor(
              (row) => {
                return {
                  properties: resolvePropertyValue(row.relationships?.relationship_properties, column.relationship!),
                  relations: isPropertyId ? resolveRelations(row.relationships?.relationship_properties, column.relationship!) : undefined,
                };
              },
              {
                id: JSON.stringify(column),
                header: () => (
                  <DataTableColumnActions
                    resourceType={TableResourceType.EVENT}
                    onSelect={(action) => onActionSelect('', action, column.relationship!, column)}
                    actions={[
                      ColumnActionType.ADD_LEFT,
                      ColumnActionType.ADD_RIGHT,
                      ...(index !== 0 ? [ColumnActionType.MOVE_LEFT] : []),
                      ...(index !== tableLayoutQuery.data.columns.length - 1 ? [ColumnActionType.MOVE_RIGHT] : []),
                      ColumnActionType.SORT,
                      ColumnActionType.HIDE,
                    ]}
                    sort={getTableSortEnumVal(column.sort)}
                  >
                    {renderRelatedRecordColumnHeader(property, relatedObjectsList)}
                  </DataTableColumnActions>
                ),
                meta: {
                  headerClassName: 'p-0',
                },
                size: 280,
                cell: (info) => {
                  const { properties: relationsProperties, relations } = info.getValue();

                  if (relations) {
                    return <CellContentRelations relations={relations} objectList={objectsQuery.data.objects} />;
                  }

                  if (!relationsProperties) {
                    return null;
                  }

                  const fullText = relationsProperties
                    ?.map((propertyValue) => {
                      if (Array.isArray(propertyValue)) {
                        return propertyValue.join(', ');
                      } else if (typeof propertyValue === 'object') {
                        return JSON.stringify(propertyValue);
                      } else if (typeof propertyValue === 'boolean') {
                        return `${propertyValue}`;
                      }
                      return propertyValue;
                    })
                    .join('\n\n');

                  const cellValue = relationsProperties?.length === 1 ? relationsProperties[0] : relationsProperties;
                  const showFullText = typeof cellValue === 'object';

                  return <CellContentDefault cell={info.cell} text={renderValue(cellValue ?? null)} fullText={showFullText ? fullText : undefined} />;
                },
              }
            );
          }
        })
        .flatMap((column) => (column ? [column] : [])) ?? [];

    const emptyColumnWithInsert = columnHelper.display({
      id: 'add_column',
      header: () => (
        <DataTableColumnActions
          resourceType={TableResourceType.EVENT}
          onSelect={(action) => {
            onActionSelect('', action, undefined, undefined);
          }}
          actions={[ColumnActionType.ADD_TO_END]}
          defaultAction={ColumnActionType.ADD_TO_END}
        >
          <PlusIcon className="h-4 w-4" />
        </DataTableColumnActions>
      ),
      meta: {
        headerClassName: 'p-0',
      },
    });

    return [...eventPropertyColumns, emptyColumnWithInsert] as ColumnDef<QueryEventsCreateData['items'][number], any>[];
  }, [
    metadataAttributeNameItems,
    relationshipsQuery.data,
    objectsQuery.data,
    columnHelper,
    tableLayoutQuery.data?.columns,
    onActionSelect,
    extractRelationshipInfo,
  ]);
};
