import { twMerge } from "tailwind-merge";

import { PropsWithClassName } from "../../../types";
import { addUrlProtocol } from "../../../shared/utils/addUrlProtocol";

interface LinkTagProperties extends PropsWithClassName {
  value: string;
}

export const LinkTag = ({ value, className, ...rest }: LinkTagProperties) => {
  return (
    <a
      {...rest}
      href={addUrlProtocol(value as string)}
      target="_blank"
      className={twMerge(
        'border border-m-blue-200 text-m-blue-600 inline-block rounded-lg px-2 py-0.5 text-xs',
        className
      )}
    >
      {value}
    </a>
  );
}
