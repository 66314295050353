import CursorClickIcon from '../../../assets/icons/cursor-click-01.svg?react';
import { useParams } from 'react-router-dom';
import { useObjectQuery } from '../../../shared/data/useObjectQuery';
import { QueryEventsCreateData } from '@bigdelta/lib-api-client';
import { FC, useMemo, useState } from 'react';
import { useRelationshipsQuery } from '../../../shared/data/useRelationshipsQuery';
import { SkeletonBlock } from '../../../components/SkeletonBlock';
import { useUrlQuery } from '../hooks/useQuery';
import { useRelatedEventsInfiniteQuery } from '../data/useRelatedEventsQuery';
import { useRecordQuery } from '../data/useRecordQuery';
import { getObjectRelationshipToEvent } from '../../../shared/utils/getObjectRelationshipToEvent';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { formatDistanceToNow } from 'date-fns';
import { AttributeValue } from './AttributeValue';


interface EventDataTableProps {
  data: QueryEventsCreateData['items'][number]['properties'];
}

const EventDataTable: FC<EventDataTableProps> = ({ data }) => {
  if (!data || !Object.keys(data).length) {
    return null;
  }

  return Object.entries(data).map(([key, value]) => (
      <div className="flex justify-start items-start w-full" key={key}>
        <div className="w-2/5 text-ellipsis text-m-olive-500 capitalize">
          <div className='flex flex-nowrap justify-start items-center'>
            {key}
          </div>
        </div>
        <div className="w-3/5 text-m-olive-600 flex flex-wrap justify-start items-center gap-1">
          <AttributeValue value={value} />
        </div>
      </div>
  ));
};

const EventSkeleton = () => (
  <li className="-ml-3 flex items-center text-md">
    <SkeletonBlock className="mr-3 h-6 w-6 rounded-full" />
    <div className="flex flex-col items-start gap-y-2">
      <div className="mt-0.5 flex items-center gap-x-2">
        <span className="text-m-olive-600">
          <SkeletonBlock className="my-4 w-20" />
        </span>
        <span className="text-m-gray-600 ">
          <SkeletonBlock className="my-4 w-20" />
        </span>
      </div>
    </div>
  </li>
);

interface EventProps {
  event: QueryEventsCreateData['items'][number];
}

const SHOW_PATH = ['Page View', 'Form Submitted'];
const PATH_KEY = '$path';

const Event: FC<EventProps> = ({ event }) => {
  const [showProperties, setShowProperties] = useState(false);
  const isPathVisible = SHOW_PATH.includes(event.event_name);

  console.log(event);
  return (
    <li className="-ml-3 flex items-start text-md">
      <div className="mr-3 flex shrink-0 h-6 w-6 items-center justify-center rounded-full bg-m-blue-600 text-m-white">
        <CursorClickIcon className="h-3 w-3" />
      </div>
      <div className="flex flex-col items-start gap-y-2">
        <div className="mt-0.5 flex">
          <span className="text-m-olive-600">{event.event_name}</span>
          <span className="mx-2 text-m-gray-600">•</span>
          <span className="text-m-gray-600 ">{formatDistanceToNow(new Date(event.created_at ?? ''), { addSuffix: true })}</span>
        </div>
        {isPathVisible && (
          <div className="text-m-olive-500">
            <AttributeValue value={event.properties[PATH_KEY]} />
          </div>
        )}
        <button className="text-md text-m-blue-600" onClick={() => setShowProperties(!showProperties)}>
            {showProperties ? "Hide properties" : "Show properties" }
        </button>
        {showProperties && (
          <EventDataTable data={event.properties} />
        )}
      </div>
    </li>
  );
};

export const RecordEvents = () => {
  const { remoteRecordId } = useParams();
  const { objectSlug } = useParams();
  const query = useUrlQuery();
  const workspaceId = query.get('workspaceId');

  const { currentWorkspaceId } = useWorkspace();

  const { data: object } = useObjectQuery({ objectSlug: objectSlug ?? '', workspaceId: workspaceId ?? '' });
  const { data: relationshipData } = useRelationshipsQuery({ workspaceId: currentWorkspaceId });
  const { data: record } = useRecordQuery({ recordId: remoteRecordId, workspaceId: workspaceId ?? '', workspaceObjectId: objectSlug });

  const rel =
    relationshipData?.relationships && object?.id
      ? getObjectRelationshipToEvent({ relationships: relationshipData.relationships, objectId: object.id })
      : undefined;

  const {
    data: events,
    isLoading: isEventsLoading,
    isSuccess: isEventsSuccess,
    fetchNextPage,
    hasNextPage,
  } = useRelatedEventsInfiniteQuery({ recordId: record?.system_id ?? '', relationshipName: rel?.name, workspaceId: workspaceId ?? '' });

  const flatEvents = useMemo(() => (events ? events.pages.flatMap((d) => d.items) : []), [events]);

  if ((!!relationshipData?.relationships && !rel) || (isEventsSuccess && !flatEvents.length)) {
    return <div className="py-4 text-md text-m-olive-400">No events found</div>;
  }

  return (
    <div style={{ height: 'calc(100vh - 140px)'}}  className="pb-4 overflow-auto">
      <ul className="ml-3 flex flex-col gap-7 border-l border-m-gray-300 pt-8">
        {isEventsLoading && (
          <>
            <EventSkeleton />
            <EventSkeleton />
          </>
        )}
        {flatEvents.map((event) => (
          <Event event={event} key={event.id} />
        ))}
      </ul>
      {hasNextPage && (
        <div className="pt-6">
          <button className="text-md text-m-blue-600" onClick={() => fetchNextPage()}>
            Load more...
          </button>
        </div>
      )}
    </div>
  );
};
