import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ActivityIcon from '../../../assets/icons/activity.svg?react';
import EventIcon from '../../../assets/icons/cursor-click-01.svg?react';

import { PageHeading } from '../../../components/PageHeading';
import { formatDistanceToNow, format } from 'date-fns';
import { useRecordQuery } from '../data/useRecordQuery';
import { useObjectQuery } from '../../../shared/data/useObjectQuery';
import { RecordEvents } from '../components/RecordEvents';
import { QuickAccessLinkType } from '@bigdelta/lib-shared';
import { getWorkspaceObjectIcon } from '../../../shared/utils/getWorkspaceObjectIcon';
import { Container } from '../../../shared/ui/Container/Container';
import { Divider } from '../../../shared/ui/Divider/Divider';
import * as Tabs from '../../../shared/ui/Tabs/Tabs';
import { RecordProperties } from '../components/RecordProperties';
import { useQuery } from '@tanstack/react-query';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { RecordProfileTab } from '../components/RecordProfileTab';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys';

export const RecordsSinglePage = () => {
  const { remoteRecordId } = useParams();
  const { objectSlug } = useParams();

  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const [tab, setTab] = useState<string | undefined>(undefined);

  const { data: object } = useObjectQuery({ objectSlug: objectSlug ?? '', workspaceId: currentWorkspaceId });
  const { data: record } = useRecordQuery({ recordId: remoteRecordId, workspaceId: currentWorkspaceId, workspaceObjectId: objectSlug });

  const profileLayoutQuery = useQuery({
    queryKey: queryKeys.single('object', objectSlug, 'profileLayout'),
    queryFn: () =>
      bigdeltaAPIClient.v1.objectsProfileLayoutsDetail(objectSlug!, {
        workspace_id: currentWorkspaceId,
        record_id: remoteRecordId!,
      }),
    enabled: !!object?.workspace_id && !!remoteRecordId,
  });

  useEffect(() => {
    if (!tab && profileLayoutQuery.data) {
      const firstTab = profileLayoutQuery.data.tabs[0]?.name;
      setTab(firstTab ?? 'events');
    }
  }, [tab, profileLayoutQuery.data]);

  const label = record && object?.label_properties.map((property) => record?.properties[property]).find(Boolean);

  if (!record || !object) {
    return null;
  }

  const tabs = profileLayoutQuery.data?.tabs ?? [];

  return (
    <>
      <Container>
        <PageHeading
          breadcrumbs={[
            {
              label: object.plural_noun,
              to: `/records/${object.api_slug}?workspaceId=${object.workspace_id}`,
              icon: getWorkspaceObjectIcon(object.object_type),
            },
            {
              label: label ? String(label) : record.id,
              description: `Last edited ${formatDistanceToNow(new Date(record.updated_at ?? ''), { addSuffix: true })}, created at ${format(
                new Date(record.created_at ?? ''),
                'yyyy E d LLL H:mm'
              )}`,
            },
          ]}
          favoriteLinkConfig={{
            label: label ? String(label) : record.id,
            type: QuickAccessLinkType.RECORD,
            data: { record: { workspaceObjectId: object.id ?? '', recordId: record.id ?? '' } },
          }}
        />
      </Container>
      <Divider />
      <Container className="flex shrink grow flex-col">
        <div className="flex w-full grow overflow-hidden">
          <div className="flex w-4/12 min-w-72 grow flex-col overflow-hidden">
            <RecordProperties record={record} object={object} />
          </div>
          <div className="flex w-8/12 grow flex-col overflow-hidden">
            {profileLayoutQuery.isSuccess && (
              <Tabs.Root value={tab} onValueChange={setTab} className="flex grow flex-col">
                <Tabs.List className="px-4">
                  {tabs.map((tab) => (
                    <Tabs.Trigger key={tab.name} value={tab.name} className="grow-0 px-6 ">
                      <ActivityIcon className="mr-2 h-4 w-4" />
                      <span>{tab.name}</span>
                    </Tabs.Trigger>
                  ))}
                  <Tabs.Trigger value="events" className="grow-0 px-6">
                    <EventIcon className="mr-2 h-4 w-4" />
                    <span>Events</span>
                  </Tabs.Trigger>
                </Tabs.List>
                {tabs.map((tab) => (
                  <Tabs.Content key={tab.name} value={tab.name} className="flex-grow flex-col p-4 radix-state-active:flex">
                    <div className="grid grid-cols-2 gap-4">
                      <RecordProfileTab tab={tab} />
                    </div>
                  </Tabs.Content>
                ))}
                <Tabs.Content value="events" className="flex-grow flex-col pl-4 radix-state-active:flex">
                  <RecordEvents />
                </Tabs.Content>
              </Tabs.Root>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};
