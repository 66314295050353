import { useCallback, useMemo } from 'react';
import { ObjectsDetailData, QueryRecordsCreateData } from '@bigdelta/lib-api-client';
import { ColumnHelper, Row } from '@tanstack/react-table';
import { useObjectsQuery } from '../../../../shared/data/useObjectsQuery';
import { ColumnActionType, DataTableColumnActions } from '../../../../shared/tables/components/DataTableColumnActions';
import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../../auth/hooks/useQueryKeys';

import { useWorkspace } from '../../../auth/hooks/useWorkspace';
import { RelationshipEntityType, TableColumnType, TableResourceType } from '@bigdelta/lib-shared';
import { resolvePropertyValue } from './utils/resolvePropertyValue';
import { getColumnActions } from './utils/getColumnActions';

import PlusIcon from '../../../../assets/icons/plus.svg?react';
import { MET_REMOTE_ID } from '../../const';
import { CellContentDefault } from '../../../../shared/tables/components/CellContentDefault';
import { createSearchParams } from 'react-router-dom';
import { getWorkspaceObjectIcon } from '../../../../shared/utils/getWorkspaceObjectIcon';
import { useTableLayout, UseTableLayoutArgs } from '../../../../shared/tables/hooks/useTableLayout';
import { renderValue } from '../../../../shared/tables/utils/renderValue';
import { getRelatedObjectsList } from '../../../../shared/tables/utils/getRelatedObjectsList';
import { suppressConsoleWarn } from '../../../../shared/utils/suppressConsoleWarn';
import { getTableSortEnumVal } from '../../../../shared/tables/utils/getTableSortEnumVal';
import { CellContentRelations } from '../../../../shared/tables/components/CellContentRelations';
import { resolveRelations } from './utils/resolveRelations';
import { renderRelatedRecordColumnHeader } from '../../../../shared/tables/utils/renderRelatedRecordColumnHeader';
import { bigdeltaAPIClient } from '../../../../client/bigdeltaAPIClient.ts';
import { CellContentTags } from '../../../../shared/tables/components/CellContentTags';
import { getUserFriendlyPropertyName } from './utils/getUserFriendlyPropertyName';
import { capitalize } from 'lodash';

interface UseTableColumnsArgs {
  columnHelper: ColumnHelper<QueryRecordsCreateData['items'][number]>;
  workspaceId?: string;
  workspaceObject?: ObjectsDetailData;
  displayProperties?: boolean;
  enableActions?: boolean;
  layout: UseTableLayoutArgs['layout'];
  onLayoutChange: UseTableLayoutArgs['onChange'];
}

export const useRecordsTableColumns = ({
  columnHelper,
  workspaceId,
  workspaceObject,
  displayProperties = true,
  enableActions = true,
  layout,
  onLayoutChange,
}: UseTableColumnsArgs) => {
  const { currentWorkspaceId } = useWorkspace();
  const queryKeys = useQueryKeys();

  const objectsQuery = useObjectsQuery({ workspaceId });

  const relationshipsQuery = useQuery({
    queryKey: queryKeys.relationships(),
    queryFn: () => bigdeltaAPIClient.v1.relationshipsList({ workspace_id: currentWorkspaceId }),
  });

  const { onActionSelect, extractRelationshipInfo } = useTableLayout({
    resourceType: TableResourceType.OBJECT,
    resourceId: workspaceObject?.id,
    layout,
    onChange: onLayoutChange,
  });

  const cellLink = useCallback(
    (row: Row<QueryRecordsCreateData['items'][number]>) => ({
      pathname: `/records/${workspaceObject?.api_slug}/${row.original.id as string}`,
      search: createSearchParams({ workspaceId: workspaceId ?? '' }).toString(),
    }),
    [workspaceId, workspaceObject?.api_slug]
  );

  const objectId = workspaceObject?.id;
  const objectType = workspaceObject?.object_type;
  const iconProperty = workspaceObject?.icon_property;

  return useMemo(() => {
    if (!layout || !relationshipsQuery.data || !objectId || !objectsQuery.data) {
      return [];
    }

    const cols = layout.columns
      .map((column, idx) => {
        const columnsCount = layout.columns.length;

        if (column.type === TableColumnType.LABEL) {
          return columnHelper.accessor(
            (row) => {
              const labelProperties = workspaceObject.label_properties;

              const firstValidLabelValue = labelProperties.map((property) => row.properties?.[property]).find((v) => v);

              return firstValidLabelValue ?? row.id;
            },
            {
              id: 'label',
              header: () => (
                <DataTableColumnActions
                  resourceId={objectId}
                  resourceType={TableResourceType.OBJECT}
                  onSelect={(action) => onActionSelect(MET_REMOTE_ID, action, undefined, column)}
                  actions={getColumnActions(idx, columnsCount, true)}
                  enable={enableActions}
                  sort={getTableSortEnumVal(column.sort)}
                >
                  {capitalize(workspaceObject.singular_noun)}
                </DataTableColumnActions>
              ),
              cell: (info) => {
                const value = info.getValue();

                const fullText = Array.isArray(value) ? value.join(', ') : undefined;

                const leadingIconUrl = iconProperty && info.row.original.properties?.[iconProperty];
                const defaultLeadingIcon = objectType ? getWorkspaceObjectIcon(objectType) : undefined;

                return (
                  <CellContentDefault
                    cell={info.cell}
                    text={renderValue(value ?? null)}
                    link={cellLink}
                    leadingIcon={defaultLeadingIcon}
                    leadingIconUrl={typeof leadingIconUrl === 'string' ? leadingIconUrl : undefined}
                    className={'font-medium'}
                    fullText={fullText}
                  />
                );
              },
            }
          );
        }

        if (column.type === TableColumnType.PROPERTY) {
          const propertyName = column.property?.property_name;

          if (!propertyName) {
            return null;
          }

          return columnHelper.accessor(`properties.${propertyName}`, {
            id: propertyName,
            header: () => (
              <DataTableColumnActions
                resourceId={objectId}
                resourceType={TableResourceType.OBJECT}
                onSelect={(action) => onActionSelect(propertyName, action, undefined, column)}
                actions={getColumnActions(idx, columnsCount, false)}
                enable={enableActions}
                sort={getTableSortEnumVal(column.sort)}
              >
                {getUserFriendlyPropertyName(propertyName)}
              </DataTableColumnActions>
            ),
            size: 200,
            meta: {
              headerClassName: 'p-0',
            },
            cell: (info) => {
              const valueFn = () => info.getValue();
              let value: ReturnType<typeof valueFn> | undefined;

              suppressConsoleWarn(() => {
                value = info.getValue();
              });

              if (Array.isArray(value)) {
                return <CellContentTags tags={value} />;
              }

              const fullText = Array.isArray(value) ? value.join(', ') : undefined;

              return <CellContentDefault cell={info.cell} text={renderValue(value ?? null)} fullText={fullText} />;
            },
          });
        }

        if (column.type === TableColumnType.RELATIONSHIP) {
          const { names: relationshipNames, property } = extractRelationshipInfo(column.relationship);

          const isPropertyId = property?.property_name === MET_REMOTE_ID;

          if (!property) {
            return;
          }

          const relatedObjectsNameList = getRelatedObjectsList(
            relationshipNames,
            RelationshipEntityType.OBJECT,
            objectId,
            relationshipsQuery.data?.relationships,
            objectsQuery.data?.objects
          ).flatMap((o) => (o ? [o] : []));

          if (!relatedObjectsNameList || relatedObjectsNameList.find((object) => !object)) {
            // throw error
            return;
          }

          return columnHelper.accessor(
            (row) => {
              return {
                properties: resolvePropertyValue(row.relationships?.relationship_properties, column.relationship!),
                relations: isPropertyId ? resolveRelations(row.relationships?.relationship_properties, column.relationship!) : undefined,
              };
            },
            {
              id: JSON.stringify(column),
              header: () => (
                <DataTableColumnActions
                  resourceId={objectId}
                  resourceType={TableResourceType.OBJECT}
                  onSelect={(action) => onActionSelect('', action, column.relationship!, column)}
                  actions={getColumnActions(idx, columnsCount, false)}
                  enable={enableActions}
                  sort={getTableSortEnumVal(column.sort)}
                >
                  {renderRelatedRecordColumnHeader(property, relatedObjectsNameList)}
                </DataTableColumnActions>
              ),
              meta: {
                headerClassName: 'p-0',
              },
              size: 280,
              cell: (info) => {
                const { properties: relationsProperties, relations } = info.getValue();

                if (relations) {
                  return <CellContentRelations relations={relations} objectList={objectsQuery.data.objects} />;
                }

                if (!relationsProperties) {
                  return null;
                }

                const fullText = relationsProperties
                  ?.map((propertyValue) => {
                    if (Array.isArray(propertyValue)) {
                      return propertyValue.join(', ');
                    } else if (typeof propertyValue === 'object') {
                      return JSON.stringify(propertyValue);
                    } else if (typeof propertyValue === 'boolean') {
                      return `${propertyValue}`;
                    }
                    return propertyValue;
                  })
                  .join('\n\n');

                const cellValue = relationsProperties.length === 1 ? relationsProperties[0] : relationsProperties;
                const showFullText = typeof cellValue === 'object';

                return <CellContentDefault cell={info.cell} text={renderValue(cellValue ?? null)} fullText={showFullText ? fullText : undefined} />;
              },
            }
          );
        }
      })
      .flatMap((col) => (col ? col : []));

    const addColumn = enableActions
      ? [
          columnHelper.display({
            id: 'add_column',
            header: () => (
              <DataTableColumnActions
                resourceId={objectId}
                resourceType={TableResourceType.OBJECT}
                onSelect={(action) => onActionSelect('', action, undefined, undefined)}
                actions={[ColumnActionType.ADD_TO_END]}
                defaultAction={ColumnActionType.ADD_TO_END}
                enable={enableActions}
              >
                <PlusIcon className="h-4 w-4" />
              </DataTableColumnActions>
            ),
            meta: {
              headerClassName: 'p-0',
            },
          }),
        ]
      : [];

    const allColumns = [...cols, ...addColumn];

    return displayProperties ? allColumns : allColumns.filter((col) => col.id === 'label');
  }, [
    cellLink,
    columnHelper,
    displayProperties,
    enableActions,
    extractRelationshipInfo,
    iconProperty,
    layout,
    objectId,
    objectType,
    objectsQuery.data,
    onActionSelect,
    relationshipsQuery.data,
    workspaceObject?.label_properties,
    workspaceObject?.singular_noun,
  ]);
};
