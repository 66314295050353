
import { QueryReportsCreatePayload, RelationshipsListData } from '@bigdelta/lib-api-client';
import { TrendsBuilderState, TrendsBuilderType } from '../../../store/TrendsBuilder';
import { getQueryMetricEvent } from './getQueryMetricEvent';
import { ReportState } from '../../../store/Report';
import { getParsedFormulaData } from '../getParsedFormulaData';
import { Filter } from '../../../../../shared/filters/store';
import { getQueryMetricRecord } from './getQueryMetricRecord';
import { ObjectCountType } from '../../../store/common';

export const getMetricFromBuilder = (
  builder: TrendsBuilderState,
  builders: TrendsBuilderState[],
  relationships: RelationshipsListData['relationships'],
  embedMetric: boolean,
  filters: { [key: string]: Filter | null } | null
): QueryReportsCreatePayload['metrics'][number] | null => {
  if (!embedMetric && builder.metricId) {
    return {
      metric: { resource: { id: builder.metricId } },
      query_name: builder.label,
    };
  }

  if (builder.data[TrendsBuilderType.FORMULA]?.expressionRaw) {
    const expressionRaw = builder.data[TrendsBuilderType.FORMULA].expressionRaw;
    const { missingBuilders, formulaBuilders, error, expressionMetrics, expression } = getParsedFormulaData(expressionRaw, builder.name, builders);

    if (error) {
      return null;
    }

    if (missingBuilders?.length || !formulaBuilders || !expressionMetrics || !expression) {
      return null;
    }

    const queryMetricsFromSaved = expressionMetrics.map((metric) => ({
      metric: { resource: { id: metric.id } },
      name: metric.queryName,
    }));

    const queryMetricsFromBuilders = formulaBuilders
      .map((builder) => {
        if (!builder) {
          return null;
        }

        const metric = getMetricFromBuilder(builder, builders, relationships, embedMetric, filters);

        if (!metric) {
          return null;
        }

        return { ...metric, name: builder.name };
      })
      .flatMap((metric) => (metric ? [metric] : []));

    const metrics = [...queryMetricsFromSaved, ...queryMetricsFromBuilders];

    if (metrics.length === 0) {
      return null;
    }

    return {
      formula: {
        expression,
        metrics,
      },
      query_name: builder.label,
      name: builder.name,
    };
  }

  if (builder.data[TrendsBuilderType.METRIC]?.metricId) {
    return {
      metric: {
        resource: { id: builder.data[TrendsBuilderType.METRIC].metricId },
      },
      query_name: builder.label,
      name: builder.name,
    };
  }

  if (!builder.data[TrendsBuilderType.OBJECT]?.count || !builder.data[TrendsBuilderType.OBJECT]?.workspaceObjectId) {
    return null;
  }

  if (builder.data[TrendsBuilderType.OBJECT].count.type === ObjectCountType.EVENT) {
    return getQueryMetricEvent(builder, relationships, filters);
  }

  if (builder.data[TrendsBuilderType.OBJECT].count.type === ObjectCountType.RECORD) {
    console.log(getQueryMetricRecord(builder, filters));
    return getQueryMetricRecord(builder, filters);
  }

  return null;
};

export const getReportQueryRequestMetrics = (
  state: ReportState,
  relationships: RelationshipsListData['relationships'] | undefined,
  embedMetrics: boolean,
  trendsBuildersFilters: { [key: string]: Filter | null }
) => {
  if (!relationships) {
    return [];
  }

  return state.report.trends.builders
    .map<QueryReportsCreatePayload['metrics'][number] | null>((builder) => {
      return getMetricFromBuilder(builder, state.report.trends.builders, relationships, embedMetrics, trendsBuildersFilters);
    })
    .flatMap((builder) => (builder ? [builder] : []));
};
