import { FC, useState } from 'react';
import { NestedObjectDef } from '@bigdelta/lib-api-client';

import { Tag } from '../../../shared/components/Tag';
import { LinkTag } from './LinkTag';
import { Badge } from '../../../shared/components/Badge';
import { twMerge } from '../../../utils/twMerge';

const URL_PATTERN = new RegExp('^((http|https):\\/\\/)?((([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6})|(\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}))(:[0-9]{1,5})?(\\/[^\\s]*)?', 'i');

interface AttributeValueProps {
  value: NestedObjectDef[string];  
}

// TODO: Update to type check from BE (URL specific type to be added)
const isValidURL = (value: string | null | number | boolean) => URL_PATTERN.test(value as string);


const MAX_TAGS = 2;

const isObject = (value: unknown) => typeof value === 'object' && !Array.isArray(value) && value !== null

export const AttributeValue: FC<AttributeValueProps> = ({ value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const badges = isObject(value) ? Object.values(value) : value;

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleHoverLeave = () => {
    setIsHovered(false);
  };


  const handleClick = () => {
    setIsOpen(!isOpen);
  }

  if (Array.isArray(badges)) {
    const subset = badges.slice(0, MAX_TAGS);
    const remaining = badges.slice(MAX_TAGS);

    const remainingCount = remaining.length;

    return (
      <>
        <div className="flex flex-wrap justify-start items-start gap-1">
          {subset.map((element, index) => isValidURL(element)
              ? (
              <LinkTag
                key={`${JSON.stringify(element)}-${index}`}
                value={element as string}
              />
            )
              : (
              <Tag
                key={`${JSON.stringify(element)}-${index}`}
                value={element}
              />)
          )}
          <div className='relative' onMouseEnter={handleHover} onMouseLeave={handleHoverLeave}>
            {remainingCount > 0  && (
              <>
                <Badge
                variant='grey'
                className={twMerge("cursor-pointer absolute pointer-events-none", isHovered && 'invisible')}
                >
                  {isOpen ? 'Show less' : `+${remainingCount}`}
                </Badge>
                <Badge
                  variant='grey'
                  className={twMerge("cursor-pointer", !isHovered && 'invisible')}
                  onClick={handleClick}
                >
                  {isOpen ? 'Show less' : 'Show more'}
                </Badge>
              </>
            )}
          </div>
        </div>
        {isOpen && (
          <div className="flex flex-wrap justify-start items-center gap-1">
            {remaining.map((element, index) => isValidURL(element)
              ? (
                <LinkTag
                  key={`${JSON.stringify(element)}-${index}`}
                  value={element as string}
                />
              )
              : (
              <Tag
                key={`${JSON.stringify(element)}-${index}`}
                value={element}
              />)
            )}
          </div>
        )}
      </>
    );
  }

  if (typeof value === 'number' || typeof value === 'string') {
    return isValidURL(value) ? <LinkTag value={value as string} /> : value;
  }

  return value ? JSON.stringify(value) : value as null;
};